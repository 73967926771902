@font-face {
  font-family: "Montserrat";
  src: url("https://app.signs.school/fonts/Montserrat-VariableFont_wght.ttf");
}

html,
body {
  margin-top: 0vw;
  width: 100vw;
  height:100vh;
  font-family: montserrat;
  overflow-x: hidden;
}

.left {
  float: left;
}
.right {
  float: right;
}
.bottom {
  float: bottom;
}

.top {
  float: top;
}

.show-class {
  visibility: visible;
}

.hide-class {
  visibility: hidden;
}

.float-bottom {
  align-self: flex-end;
}

* {
  margin: 0;
  padding: 0;
}
*:focus {
  outline: none;
}

.section {
  width: 100%;
  text-algin: center;
}

.box-main {
  width: 100%;
  align-items: center;
  margin: auto;
}

.separator {
  width: 90%;
  height: 0.1vh;
  margin-top: 1%;
  background-color: #d9d9d9;
  margin: auto;
}

.small-separator {
  width: 80%;
}
.medium-separator {
  width: 90%;
}

a {
  color: black;
  text-decoration: none;
}

a:hover,
button:hover {
  color: #363636;
}

.button:hover {
  background-color: black;
  color: white;
}

h1 {
  text-align: center;
  top: 18%;
  left: 10%;
  right: 10%;
  font-size: 4rem;
  position: absolute;
}

h2 {
  text-align: center;
  position: absolute;
  top: 63%;
  left: 10%;
  right: 10%;
}

h4 {
  margin-top: 1%;
  font-size: 3rem;
}

h5 {
  padding-top: 5%;
}

h6 {
  text-align: center;
  font-size: 2rem;
}
.first-top-margin {
  margin-top: 20%;
}

.landing-margin {
  top: 75%;
  position: absolute;
  left: 40%;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.firstHalf {
  height: 70vh;
  text-align: center;
}

.layer-top {
  z-index: 10;
}

.thirdHalf {
  display: grid;
  grid-template-columns: 50% 50%;
}

.landing-background {
  width: 100%;
  left: 0;
  position: relative;
}

.fourfthHalf {
  width: 100%;
}
.eco-slide {
  width: 100%;
}

.fourteenthHalf,
.fifteenthHalf,
.sixteenthHalf,
.seventeenthHalf,
.eighteenthHalf {
  background-color: #7480ff;
  width: 100%;
  height: 100vh;
}

.seventhHalf {
  background-color: #f4f4f4;
  height: 25vh;
  display: flex;
  justify-content: space-around;
}

.seventhAndAHalf {
  display: grid;
  grid-template-columns: 50% 50%;
}

.ninthHalf {
  display: grid;
  grid-template-columns: 50% 50%;
}
.ninthAndAHalf {
  display: grid;
  grid-template-columns: 40% 60%;
}
.tenthHalf {
  width: 85%;
  height: 70vh;
  display: flex;
  border-radius: 1.5rem;
  border: 2px solid black;
}
.eleventhHalf {
  display: grid;
  grid-template-columns: 50% 50%;
}

.thirteenthHalf {
  width: 80%;
  height: 29.2vh;
  background-color: #f8f8f8;
  border-radius: 2rem;
  display: grid;
  grid-template-columns: 60% 40%;
}

.nineteenthHalf {
  height: 100%;
  text-align: center;
}

.cpa-div {
  width: 35%;
  margin-left: 5%;
  margin-top: 1%;
  display: flex;
  justify-content: space-around;
}
.wide {
  width: 60%;
}

.cpa-div-wide {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  display: flex;
  justify-content: space-around;
}

.button {
  background-color: transparent;
  height: 15%;
  width: 10%;
  border: 3px solid black;
  border-radius: 1rem;
  font-size: 1em;
}
.small-border {
  border: 1px solid black;
}
.light-border {
  border: 2px solid black;
}

.loginButton {
  font-size: 1.2rem;
  height: 6vh;
  border-radius: 5rem;
  border: none;
}

.white-button {
  height: 2%;
  padding: 2%;
  width: 100%;
  border-radius: 25px;
  color: black;
  font-size: 0.75rem;
  background-color: white;
}

.cpa-button {
  background-color: transparent;
  height: 5vh;
  width: 45%;
  border: 2px solid black;
  border-radius: 1rem;
  font-size: 1em;
}

.round-button {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #e4e4e4;
  border-radius: 1rem;
}

.white-outline {
  border: 2px solid white;
}

.cpa-button:hover {
  background-color: black;
  color: white;
}

.cpa-button-light {
  background-color: transparent;
  height: 5vh;
  width: 20%;
  border: 1px solid black;
  border-radius: 0.5rem;
  font-size: 1em;
}

.cpa-button-light:hover {
  background-color: black;
  color: white;
}

.basic-shape {
  background-color: white;
  width: 20%;
  height: 70%;
  margin-top: 2%;
  border-radius: 1rem;
}

.border {
  border: 3px solid black;
}

.light-background {
  width: 80%;
  height: 30vh;
  border-radius: 2rem;
  background-color: #aaaaff;
}

.black-background {
  width: 80%;
  border-radius: 2rem;
  background-color: black;
}
/*
.news-background {
  background: url("../lib/assets/signs-news-background.png");
  background-position: top;
  background-size: 100% 100%;
}*/

.clay-background {
  background-color: #1e1e1e;
}
.dark-gray-background {
  background-color: #323232;
}
.green-background {
  background-color: #45d086;
}
.red-background {
  background-color: #ff8686;
}
.dark-blue-background {
  background-color: #7480ff;
}
.dark-blue-color {
  color: #7480ff;
}
.light-gray-color {
  color: #cecece;
}
.gray-color {
  color: #a5a5a5;
}
.navy-background {
  background-color: #03061e;
}

.signin-input {
  border: none;
  height: 6vh;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  background-color: #323232;
  padding-left: 15px;
  color: #ffffff;
}

.form-input {
  border: 0.1px solid #a5a5a5;
  height: 5vh;
  border-radius: 0.5rem;
  background-color: transparent;
  font-size: 1.1rem;
  padding-left: 15px;
  color: white;
}
.form-input:focus {
  border-color: white;
}

.clay-height {
  height: 60vh;
}
.fixed-seventh-height {
  height: 70vh;
}
.fixed-max-height {
  height: 100vh;
}
.fixed-extreme-height {
  height: 128vh;
}
.fixed-half-height {
  height: 30vh;
}
.fixed-feature-height {
  height: 11vw;
}
.fixed-news-height {
  height: 15vw;
}
.fixed-small-height {
  height: 20vh;
}

.circle {
  width: 10%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  z-index: 5;
  /*width: 5vh;
  height: 5vh;*/
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vertical-align-text {
  padding-top: 0;
  padding-bottom: 0;
}

.white-background {
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
}
.transparent-background {
  background-color: transparent;
}

.feature-background {
  background-color: #aaaaff;
  border: 10px solid #f8f8f8;
  border-radius: 2rem;
}

.even-rounder {
  border-radius: 1.5rem;
}

.black {
  background-color: black;
  color: white;
}
.black:hover {
  background-color: white;
  color: black;
}

.static-black-background {
  background-color: black;
  color: white;
}

.gray {
  color: #8a8a8a;
}

. .light {
  background-color: #aaaaff;
  color: white;
}
.light-color {
  color: #aaaaff;
}

.large {
  width: 65%;
  height: 3vw;
  margin-top: 5%;
}

.small {
  width: 20%;
  height: 5vh;
  margin-left: 2%;
}

.high {
  height: 20vh;
}
.extra-high {
  height: 25vh;
}

.half {
  width: 15%;
}

.benefit-container {
  display: flex;
  justify-content: space-around;
}

.benefit-sub-container {
  display: grid;
  grid-template-columns: 10% 90%;
}

.checkmark {
  width: 80%;
}

.heading-small {
  margin-left: 5%;
  font-size: 2rem;
}

.p {
  margin-top: 1%;
  margin-left: 5%;
  width: 80%;
}

.semi-cut-width {
  margin-left: 50%;
  margin-top: 10%;
  width: 80%;
}

.cut-width {
  margin-left: 50%;
  margin-top: 10%;
  width: 60%;
}

.p-extended-width {
  width: 90%;
}

.medium {
  font-size: 1.2rem;
}
.medium-large {
  font-size: 1.6rem;
}
.large-font {
  font-size: 3rem;
}
.really-large {
  font-size: 2rem;
}
.really-large-font {
  font-size: 4rem;
}

.assignments-background {
  width: 100%;
  height: 60vh;
  background-color: #ffb74a;
}

.enum-head {
  margin-left: 5%;
  font-size: 1.5rem;
}

.feature-image {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.icon:hover {
  scale: 1.1;
}

.background-image {
  width: 100%;
}
.half-image {
  width: 50%;
}
.small-image {
  width: 30%;
}
.border-radius {
  border-radius: 1rem;
}
.more-border-radius {
  border-radius: 1.5rem;
}

.box-container {
  display: grid;
  grid-template-columns: 20% 50% 30%;
  margin-top: 4%;
}
.big-box {
  display: grid;
  grid-template-columns: 1fr;
}
.a-box {
  height: 10vh;
  display: grid;
  grid-template-columns: 15% 85%;
}
.box {
  width: 50%;
  display: grid;
  grid-template-columns: 20% 80%;
}

.small-container {
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.small-height {
  height: 15%;
}

.extended-height {
  height: 50%;
}
.semi-height {
  height: 85%;
}

.sixth-height {
  height: 60%;
}

.full-height {
  height: 100%;
}

/*I know these names are awkward
but if anyone ever reads this shit it kinda worked
*/
.mini-width {
  width: 2.5%;
}
.small-width {
  width: 5%;
}

.extended-width {
  width: 100%;
}
.tenth-width {
  width: 10%;
}
.fifth-width {
  width: 20%;
}
.third-width {
  width: 30%;
}
.fourfth-width {
  width: 40%;
}
.fixed-news-width {
  width: 30vw;
}

.spaced-half-width {
  width: 47%;
}
.extreme-width {
  width: 130%;
}
.ninth-width {
  width: 90%;
}
.semi-width {
  width: 75%;
}
.sixth-width {
  width: 60%;
}

.third-half-width {
  width: 33%;
}
.limited-width {
  width: 70%;
}
.reduced-width {
  width: 85%;
}
.half-width {
  width: 50%;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.container {
  width: 80%;
  display: flex;
  justify-content: space-around;
}
.mid {
  position: relative;
  left: 10%;
  right: 10%;
}
.grid {
  display: grid;
}
.table {
  display: flex;
  flex-wrap: wrap;
}

.two {
  grid-template-columns: 50% 50%;
}
.two-two {
  grid-template-columns: 30% 75%;
}
.two-two-two {
  grid-template-columns: 45% 55%;
}
.two-thirds {
  grid-template-columns: 70% 30%;
}
.settings-content {
  grid-template-columns: 15% 75% 10%;
}

.tenth-ninth {
  /*I picked this name but it makes no sense*/
  grid-template-columns: 20% 80%;
}

.block {
  display: block;
}

table {
  width: 95vw;
  display: grid;
  justify-content: left;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}

tbody {
  display: flex;
}

table::-webkit-scrollbar {
  display: none;
}

.slide {
  display: grid;
  grid-template-columns: 1fr;
  width: 50%;
  height: 41vh;
  margin-left: 1%;
  border-radius: 1.3rem;
  background-color: black;
}

td {
  width: 25vw;
}

.slide-image {
  width: 95%;
}

.slide-image-small {
  width: 80%;
  margin-top: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-image {
  width: 100%;
  height: 41vh;
  margin-left: 1%;
  border-radius: 1.3rem;
}
.wide-image {
  width: 90%;
}

.transparent {
  background-color: transparent;
}
.light {
  background-color: #aaaaff;
}
.navbar {
  display: grid;
  grid-template-columns: 80% 20%;
}
.navigation {
  display: flex;
  margin-left: auto;
}
a:hover {
  color: gray;
}

.nav-list {
  width: 70%;
  display: grid;
  grid-template-columns: 20% 80%;
}

/*STUNDENPLAN*/

.red-left-margin {
  width: 80%;
  margin-left: 0%;
  margin-top: 20%;
}

.add-left-margin {
  margin-left: 60%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 110px;
}

.nav-list li {
  list-style: none;
  padding: 26px 30px;
}

.background {
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.7511379551820728) 0%,
    rgba(255, 255, 255, 0.5018382352941176) 100%
  );
  backdrio-filter: blur(2px);
  z-index: -1;
}

.section-Left {
  flex-direction: row-reverse;
}

.centric {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.center {
  justify-content: space-around;
  text-align: center;
}

.horizontal-align {
  line-height: 100%;
}

.flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.italic {
  font-style: italic;
}

.inline-block {
  display: inline-block;
  white-space: nowrap;
}

.semi-bold {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.light-font {
  font-weight: 400;
}
.full-width {
  width: 70%;
}
.max-width {
  width: 100%;
}

.black-color {
  color: black;
}

.white {
  color: white;
}

h5 {
  font-size: 2rem;
}

.decoration-box {
  width: 2vw;
  height: 2vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 0.5rem;
  background-color: #d7d7ff;
}

.footer-input {
  height: 5vh;
  width: 60%;
  background: transparent;
  border: 1px solid #9a9a9a;
  border-radius: 0.5vw;
  padding-left: 15px;
  font-size: 1rem;
}

.links-container {
  display: grid;
  grid-template-columns: 5% 85%;
  margin-top: 1%;
  margin-left: 5%;
}

.why-margin {
  margin-top: 0.2%;
}

.socials-box {
  display: flex;
  justify-content: space-around;
}
.social-badge {
  width: 25%;
  height: 2.1vh;
}

.apps-box {
  text-align: center;
}
.app-badge {
  width: 8%;
}
.web-app-badge {
  width: 8.8%;
}

.left-align {
  text-align: left;
}

.footer-link-container {
  display: flex;
  width: 20%;
  margin-left: 5%;
  margin-top: 15%;
  justify-content: space-between;
}

.footer-separator {
  width: 90%;
  margin: auto;
  margin-top: 0.5%;
  color: #d9d9d9;
}
.no-border-radius {
  border-radius: 0%;
}
.no-margin {
  margin-left: 0%;
}

.footer-community-text {
  font-size: 2rem;
  width: 90%;
  text-align: left;
}
.footer-container {
  display: grid;
  height: 15vh;
  margin-left: 5%;
  grid-template-columns: 35% 35% 30%;
}

.footer {
  height: 45vh;
  background-color: #f3f3f3;
}
.small-left-margin {
  margin-left: 3%;
}
.small-right-margin {
  margin-right: 2%;
}
.left-margin {
  margin-left: 5%;
}
.more-left-margin {
  margin-left: 10%;
}

.medium-left-margin {
  margin-left: 15%;
}

.even-more-left-margin {
  margin-left: 20%;
}
.third-left-margin {
  margin-left: 30%;
}
.half-left-margin {
  margin-left: 40%;
}

.small-padding-left {
  padding-left: 5%;
}
.padding-left {
  padding-left: 20%;
}
.padding-bottom {
  padding-bottom: 100%;
}

.square {
  width: 11vw;
  height: 11vw;
}

.text-mini-margin-top {
  padding-top: 1%;
}
.text-small-margin-top {
  padding-top: 2%;
}
.text-margin-top {
  padding-top: 4%;
}
.text-margin-more-top {
  padding-top: 10%;
}
.text-margin-half-top {
  padding-top: 20%;
}
.text-margin-third {
  padding-top: 30%;
}
.text-margin-fourfth {
  padding-top: 40%;
}
.text-margin-even-more-top {
  padding-top: 50%;
}

.small-top-margin {
  margin-top: 1%;
}
.semi-top-margin {
  margin-top: 2%;
}
.minimal-top-margin {
  margin-top: 0.5%;
}
/*.top {
  z-index: 3;
}*/
.top-margin {
  margin-top: 5%;
}
.more-top-margin {
  margin-top: 10%;
}
.some-top-margin {
  margin-top: 15%;
}
.even-more-top-margin {
  margin-top: 20%;
}

.half-top-margin {
  margin-top: 35%;
}

.three-quarter-top-margin {
  margin-top: 75%;
}

.small-bottom-margin {
  margin-bottom: 5%;
}
.bottom-margin {
  margin-bottom: 10%;
}
.more-bottom-margin {
  margin-bottom: 15%;
}
